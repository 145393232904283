.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}

.ql-container {
  height: auto;
  flex-grow: 1;
}

.ql-editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;
}

.p-container-email {
  padding-bottom: 5rem;
}

.mh-react-quill-custom-component {
  max-height: 25rem;
  overflow-y: scroll;
}

.preview-email-custom-component {
  height: 34.375rem;
  max-width: 50%;
}

.custom-overflow {
  overflow-y: scroll;
}

.custom-mw-component {
  max-width: 50%;
}

.button-create-template {
  background: #d0d0d0;
  border-radius: 2rem;
  padding: 0.4rem;
}

.template-custom-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: min-content;
}

.picture-company-email {
  width: 6.25rem;
}

.edit-mail-info-container {
  border: 1px solid #ccc;
}

.subject-input {
  padding: 0.563rem 0.5rem 0.563rem 1rem;
  gap: 0.5rem;
  height: 3.125rem;
  border: 1.5px solid #bbbbbb;
  border-radius: 8px;
}

.subject-input::placeholder {
  font-style: italic;
}

@media (max-width: 992px) {
  .preview-email-custom-component,
  .custom-mw-component {
    min-width: 100%;
  }
}

.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #56c1ff;
  color: white !important;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  text-decoration: none !important;
}

.custom-button:hover {
  background-color: #49a9e7;
}

.custom-button span {
  margin-left: 10px;
  display: inline-block;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.custom-button:hover span {
  transform: translateX(5px);
}

.text-title {
  font-size: 20px !important;
}
