@import '../../variables.scss';

.modal_submission {
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: d-none;
  background-color: rgba(31, 32, 41, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  z-index: 101 !important;
}

.visible_submission {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
  overflow: d-none;
}

.modal__wrap_submission {
  width: 70% !important;
  max-width: 112.5rem !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: auto;
  margin-bottom: auto;
  padding: 2rem 4rem 1rem 4rem;
  align-self: start;
  max-height: calc(100vh - 2.3rem);
  opacity: 0;
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow-y: auto;
}

.modal__wrap_submission.testgroup-details {
  width: 80% !important;
  height: 90% !important;
  padding: 0;
}

.arrow-dropdown-modal-collapsed {
  transform: rotate(-180deg);
  transition: transform 0.5s;
}

.arrow-dropdown-modal-uncollapsed {
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.visible_submission .modal__wrap_submission {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.button-close-modal {
  color: #4987be;
  width: 1.375rem;
  height: 1.375rem;
}

.button-close-modal.testgroup-details {
  color: #272727;
}

.text-blue-modal {
  color: #41479b;
}

.color-title-test {
  color: rgba(48, 48, 48, 0.8);
}

.color-count-questions {
  color: #5e5e5e;
}

.border-language-selected {
  border: 1px solid $grey-2;
  border-radius: 1px;
  width: 7rem;
}

.border-item-test-group,
.border-item-test-group-collapsed {
  border: 1px solid rgba(26, 172, 240, 0.72);
  border-radius: 8px;
  transition: all 0.5s;
}

.border-item-test-group-collapsed {
  border-top: 0;
  margin-top: -0.188rem !important;
  transition: all 0.5s;
}

.text-color-item-test-group {
  color: rgba(75, 75, 75, 0.75);
  max-width: 90%;
}

.text-color-collapsed-modal {
  color: rgba(48, 48, 48, 1);
}

.button-close-modal-submission {
  background: $blue-principal;
  border-radius: 22px;
}

.bg-container-options-modal {
  background: rgba(85, 82, 249, 0.03);
}

.modal-container-text-custom {
  background: rgba(88, 193, 245, 0.19);
  border-radius: 8px;
}

.modal-border-bottom-dashed {
  border-bottom: 2px dashed $blue-principal;
}

.margin-icon-incorrect-custom {
  margin-left: 2px;
}

.modal-container-check-result {
  border: 3px solid rgba(82, 192, 247, 0.86);
  border-radius: 4px;
  width: 1.4375rem;
  height: 1.4375rem;
}

@media (max-width: 500px) {
  .modal__wrap {
    width: 90% !important;
    padding: 2rem 2rem 1rem 2rem;
  }

  .text-category-responsive {
    font-size: 10px;
  }
}

.ant-progress-outer {
  padding-right: 0 !important;
}

.disclaimer-yellow-bg {
  background: #ffe47f;
  padding: 0.625rem;
  margin-top: 1.25rem;
}

.best-option {
  gap: 0.625rem;
  width: 8rem;
  height: 1.32rem;
  background: #e4fcef;
  border-radius: 0.125rem;
  line-height: 0.938rem;
  letter-spacing: 0.07em;
  text-align: left;
  color: rgba(10, 102, 69, 1);
}

.tg-description {
  max-height: 6rem;
  overflow: auto;
  word-wrap: break-word;
  white-space: pre;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}

@media (min-height: 770px) {
  .tg-description {
    max-height: 12.5rem;
  }
}

.text-medium {
  font-size: 1rem;
}

.ticket-difficult {
  font-size: 0.715rem;
  line-height: 0.815rem;
  letter-spacing: 0.05em;
  padding: 0.075rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
}

.ticket-color-VERY_EASY,
.ticket-color-EASY {
  color: #58dda6;
  background-color: #c2f2de;
}

.ticket-color-INTERMEDIATE {
  color: #808080;
  background-color: #ebebeb;
}

.ticket-color-HARD {
  color: #808080;
  background-color: #feebeb;
}

.ticket-color-VERY_HARD,
.ticket-color-ADVANCED {
  color: #fd6466;
  background-color: #feebeb;
}

.ticket-position {
  top: 0rem;
  left: -1rem;
  border-radius: 8px 0 0 0;
}

.container-detail-item-submission {
  width: 3rem;
}

.details-header {
  background-color: $blue-mid;
  padding: 2rem;
  display: flex;

  .title {
    font-size: 1.375rem;
  }
}

.details-header.example-questions {
  background-color: #ffffff;
}

.see-questions-button {
  background-color: $blue-principal;
  color: #ffffff;
  border-radius: 1.375rem;
  border: none;
  padding: 0.5rem 1rem;
  gap: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -4px 4px 0px #00000040 inset;
  justify-self: flex-end !important;
  cursor: pointer !important;
}

.bg-blue-light {
  background-color: #bae2f6;
}

.box-item {
  display: flex;
  flex-direction: column;
  width: 11.75rem;
  height: 5.25rem;
  max-height: 5.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .no-gap {
    gap: 0;
  }

  .gap-formats {
    gap: 0.2rem;
  }

  .overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.box-item-content {
  overflow-y: scroll !important;
  justify-content: center;
}

.box-item svg {
  width: 2.5rem;
  height: 2.5rem;
}

.box-item img {
  border-radius: 50%;
  margin-right: 0.2rem;
  object-fit: contain;
}

.box-item > * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-size-24 {
  font-size: 1.5rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.gray-pill {
  display: flex;
  background-color: #ececec;
  border-radius: 1.25rem;
  padding: 0.5rem 0.75rem;
  color: #686868;
}

.detail-boxes-container {
  display: flex;
  padding: 0 2rem 2.5rem 2rem;
  gap: 1.3rem;
}

.gap-m {
  gap: 1rem;
}

.image-wrapper {
  position: relative;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(10px);
  z-index: 0;
  /* Fondo difuminado */
}

.image-wrapper img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  /* Imagen principal */
}

.img-endorsement {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
}

.tags-container {
  background-color: #f5f6f6;
  border-radius: 0.4rem;
  color: #272727;
  font-weight: 600;
  font-size: 0.75rem;
}

.w-1-3 {
  width: 33.33%;
}

.w-1-2 {
  width: 50%;
}

.first-container,
.second-container {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.second-container {
  p,
  h3,
  span {
    font-weight: 500 !important;
  }
}

.third-container {
  font-weight: 500;
}

.testgroup-content-columns {
  display: flex;
}

@media (max-width: 1680px) {
  .testgroup-content-columns {
    // flex-direction: column;
    flex-wrap: wrap;
  }

  .first-container,
  .second-container {
    width: 50%;
    height: auto !important;
  }

  .third-container {
    width: 100%;
    padding-top: 1rem;
  }
}

@media (max-width: 1200px) {
  .first-container,
  .second-container,
  .third-container {
    width: 100%;
  }
}

.tests-container {
  padding: 2rem;
}

.test-item {
  background-color: #ececec;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 2rem 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #686868;
}

.bg-grey-light {
  background-color: #f5f6f6;
}

.pointer {
  cursor: pointer;
}
