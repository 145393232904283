@import '../../variables.scss';

.new-search-container {
  p,
  button,
  span {
    font-style: normal;
  }
}

.url-input-placeholder {
  border-radius: 0.5rem 0px 0px 0.5rem;
  padding: 0.5rem 1rem;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  outline: none;
  text-overflow: ellipsis;
  width: 75%;
  color: $grey-2;
  border: 2px solid $blue-principal !important;
}

.ant-tooltip-inner {
  // font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
}

.copy-url-button {
  background-color: $blue-principal !important;
  padding: 0.5rem 1rem;
  color: white;
  font-style: normal;
  font-size: 0.75rem;
  font-weight: 700;
  outline: none;
  border-radius: 0px 0.5rem 0.5rem 0px;
  border: none;
}

.search-success-container {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.share-buttons-title {
  font-size: 0.875rem;
  font-weight: 700;
  color: $blue-dark;
}

.share-button-item {
  border-radius: 50%;
  background: transparent;
  margin: 0;
  width: 2.3125rem;
  height: 2.3125rem;
  border: 2.5px solid $blue-dark !important;

  svg {
    color: $blue-dark;
  }
}
