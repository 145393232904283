@import '../../variables.scss';

.multiple-select-options-container {
  width: max-content;
  min-width: 10.25rem;
  max-height: 21.875rem;
  overflow-y: auto;
  margin-top: 2.125rem;
  z-index: 3;
}

.multiple-select-container {
  border-radius: 8px;
}

.select-border {
  border: 0.063rem solid;
}

.border-open {
  border-color: $blue-principal;
}

.border-closed {
  border-color: #eff0f4;
}

.filters-dropdown {
  width: 100%;

  .multiple-select-container {
    padding: 0.563rem !important;
    border-color: $blue-principal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .multiple-select-options-container {
    margin-top: 2.25rem !important;
    width: 100%;
  }
}

.ant-select-tree {
  font-size: 12px !important;
  padding: 1px !important;

  .ant-select-tree-node-content-wrapper {
    line-height: 15px;
    padding-top: 0.3rem;
  }
}

.tree-select-custom {
  & * {
    .ant-select-selector,
    .ant-select {
      border-radius: 5px !important;
    }

    .ant-select-selection-placeholder {
      color: #000;
      font-weight: bold;
      display: block;
    }

    .ant-select-tree-title {
      font-size: 4px !important;
      font-weight: bold;
    }

    .ant-select-selection-overflow-item {
      display: none;
    }

    .ant-select-tree-checkbox-inner {
      border-radius: 4px;
    }
  }
}
