@import '../../variables.scss';

.multiple-select-options-container {
  width: max-content;
  min-width: 10.25rem;
  max-height: 21.875rem;
  overflow-y: auto;
  margin-top: 2.125rem;
  z-index: 3;
}

.multiple-select-container {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.select-border {
  border: 0.063rem solid;
}

.border-open {
  border-color: $blue-principal;
}

.border-closed {
  border-color: #eff0f4;
}

.filters-dropdown {
  width: 100%;
  .multiple-select-container {
    padding: 0.563rem !important;
    border-color: $blue-principal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .multiple-select-options-container {
    margin-top: 2.25rem !important;
    width: 100%;
  }
}
